.smartbanner {
  left:0;
  bottom:0;
  width:100%;
  height:78px;
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  background:#fff;
  overflow:hidden;
  border-top: 1px solid #ccc;
  -webkit-font-smoothing:antialiased;
  -webkit-text-size-adjust:none;
  z-index: 99999999999;
  position: fixed;
  .smartbanner-container {
    margin: 0 auto;
    .smartbanner-close {
      position:absolute;
      left:7px;top:7px;
      display:block;
      font-family:'ArialRoundedMTBold',Arial;
      font-size:15px;
      text-align:center;
      text-decoration:none;
      border-radius:14px;
      -webkit-font-smoothing:subpixel-antialiased;
      border:0;
      width:17px;
      height:17px;
      line-height:17px;
      color:#b1b1b3;
      background:#efefef;
    }
    .smartbanner-close:active,.smartbanner-close:hover {
      color:#333;
    }
    .smartbanner-icon{
      position:absolute;
      left:30px;
      top:10px;
      display:block;
      width:57px;
      height:57px;
      background-color: #fff;
      background-size:cover;
      background-image: url("/img/fsy-icon.png");
    }
    .smartbanner-info{
      position:absolute;
      left:98px;
      top:15px;
      width:44%;
      font-size:12px;
      line-height:1.2em;
      font-weight:bold;
      color:#999;
    }
    .smartbanner-title {
      font-size:15px;
      line-height:17px;
      color:#000;
      font-weight:bold;
    }
    .smartbanner-button{
      position:absolute;
      right:20px;
      top:24px;
      border-bottom:3px solid #b3c833;
      padding:0 10px;
      min-width:12%;
      height:24px;
      font-size:14px;
      line-height:24px;
      text-align:center;
      font-weight:bold;
      color:#fff;
      background-color:#b3c833;
      text-decoration:none;
      border-radius:5px;
    }
    .smartbanner-button:hover {
      background-color: #c1d739;
      border-bottom:3px solid #8c9c29;
    }
    .smartbanner-button-text {
      text-align:center;
      display:block;
      padding:0 5px;
    }
    
    .smart-banner-countdown{
      color: #999;
      font-family: helvetica;
      font-size: 12px;
      position: absolute;
      right: 20px;
      top: 56px;
    }
  }
}